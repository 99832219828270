import React, { Component } from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import "./App.css";
import { auth } from "./components/Firebase/firebase";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Main from "./components/Main";
import Confirmation from "./components/create/Confirmation";
import AppContextProvider from "./components/context/AppContext";

class App extends Component {
  state = {
    auth: true,
    user: null,
  };
  componentDidMount() {
    /*auth
      .createUserWithEmailAndPassword("andreweijie@gmail.com", "Many@1234")
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
        console.log(errorMessage);
      });*/
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ user, auth: true });
        this.props.history.replace("/main/TCS/dashboard");
      } else {
        this.setState({ auth: false });
      }
    });
  }

  render() {
    return (
      <AppContextProvider>
        <div className="App">
          <Route exact path="/" render={() => <Redirect to="/main/TCS" />} />
          <Route exact path="/login" render={(props) => <Login {...props} />} />
          <PrivateRoute
            path="/main"
            component={Main}
            auth={this.state.auth}
          ></PrivateRoute>
          <Route exact path="/confirmation" component={Confirmation}></Route>
        </div>
      </AppContextProvider>
    );
  }
}

export default withRouter(App);
