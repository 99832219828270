import React, { Component } from "react";
import Outlet from "./Outlet";

export default class Cmpb extends Component {
  render() {
    return (
      <div className="CMPB">
        <Outlet outlet="CM-PB" />
      </div>
    );
  }
}
