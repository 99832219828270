import React, { Component } from "react";
import { Route, Link, Switch } from "react-router-dom";

export default class Select extends Component {
  render() {
    return (
      <div className="outlet-select">
        <div className="outlet-choice">
          <Link to="/main/tcs/dashboard">
            <img src="https://i.imgur.com/wWxOWWa.png" alt="test" />
          </Link>
          <Link to="/main/tcs/dashboard">
            <h1>The Coastal Settlement</h1>
          </Link>
        </div>
        <div className="outlet-choice">
          <Link to="/main/cm-pb/dashboard">
            <img
              style={{ filter: "invert(1)" }}
              src={require("../images/CM-PB.png")}
              alt="test"
            />
          </Link>
          <Link to="/main/cm-pb/dashboard">
            <h1>Contemporary Melting-Pot & Bar</h1>
          </Link>
        </div>
      </div>
    );
  }
}
