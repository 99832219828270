import React, { Component } from "react";
import Outlet from "./Outlet";

export default class Tcs extends Component {
  render() {
    return (
      <div className="TCS">
        <Outlet outlet="TCS" />
      </div>
    );
  }
}
