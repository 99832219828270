import React, { Component } from "react";
import Dashboard from "./dashboard/Dashboard";
import Confirmation from "./create/Confirmation";
import Today from "./date/Today";
import Floor from "./Floor";
import History from "./History";
import CreateNew from "./create/CreateNew";
import { Route, Link, Switch } from "react-router-dom";
import { NavBar } from "./NavBar";
import Ops from "./Ops";

export default class Outlet extends Component {
  render() {
    return (
      <div className="outlet">
        <img src={require(`../images/${this.props.outlet}.png`)}></img>
        <span>ver 1.3</span>
        <NavBar outlet={this.props.outlet}></NavBar>
        <Route
          path={`/main/${this.props.outlet}/dashboard`}
          render={(props) => (
            <Dashboard
              {...props}
              setCount={() => this.setCount}
              outlet={this.props.outlet}
            />
          )}
        />
        <Route
          path={`/main/${this.props.outlet}/edit/:reserve`}
          render={(props) => (
            <CreateNew {...props} outlet={this.props.outlet} />
          )}
        />
        <Route
          path={`/main/${this.props.outlet}/today`}
          render={(props) => <Today {...props} outlet={this.props.outlet} />}
        />
        <Route
          path={`/main/${this.props.outlet}/history`}
          render={(props) => <History {...props} outlet={this.props.outlet} />}
        />
        <Route
          path={`/main/${this.props.outlet}/floor`}
          render={(props) => <Floor {...props} outlet={this.props.outlet} />}
        />
        <Route
          path={`/main/${this.props.outlet}/ops`}
          render={(props) => <Ops {...props} outlet={this.props.outlet} />}
        />

        <Route
          path={`/main/${this.props.outlet}/confirmation`}
          render={(props) => (
            <Confirmation {...props} outlet={this.props.outlet} />
          )}
        />
      </div>
    );
  }
}
