import React, { Component } from "react";
import { Route } from "react-router-dom";
import Cmpb from "./Cmpb";
import Tcs from "./Tcs";
import Select from "./Select";

export default class Main extends Component {
  render() {
    return (
      <div id="mains">
        <Route path="/main/select" component={Select}></Route>
        <Route path="/main/cm-pb" component={Cmpb} />
        <Route path="/main/tcs" component={Tcs} />
      </div>
    );
  }
}
