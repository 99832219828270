import { contextType } from "rc-time-picker";
import React, { useContext } from "react";
import { Link, NavLink } from "react-router-dom";
import { Badge } from "flwww";
import { AppContext } from "./context/AppContext";
export const NavBar = (props) => {
  const App = useContext(AppContext);
  console.log(App.unaddressed)
  return (
    <div className="nav-filter-bar">
      <div className="links">
        <NavLink
          activeClassName="tcs-active"
          to={`/main/${props.outlet}/dashboard`}
        >
          <Badge dot={App.unaddressed > 0} style={{ border: "none" }}>
            Pending
          </Badge>
        </NavLink>

        <NavLink
          activeClassName="tcs-active"
          to={`/main/${props.outlet}/today`}
        >
          <Badge dot={App.unaddressedToday > 0} style={{ border: "none" }}>
            Today
          </Badge>
        </NavLink>
        <NavLink
          activeClassName="tcs-active"
          to={`/main/${props.outlet}/history`}
        >
          History
        </NavLink>
        <a target="_#" href="https://tcs-reservation.spiceoflife.sg/#/">
          Create
        </a>
        <NavLink
          activeClassName="tcs-active"
          to={{
            pathname: `/main/${props.outlet}/floor`,
            state: { date: new Date() },
          }}
        >
          Tables
        </NavLink>
        <NavLink activeClassName="tcs-active" to={`/main/${props.outlet}/ops`}>
          Ops
        </NavLink>
      </div>
    </div>
  );
};
